import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import {
  Paper,
  Container,
  TextField,
  Button,
  Select,
  InputLabel,
  Grid,
  FormControl,
  FormControlLabel, Switch
} from '@mui/material';
const backgroundImageURL = '/divinemercyanakara.jpg';

const ReportPage = () => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const [groupsWithParticipants, setGroupsWithParticipants] = useState([]);

  const [comments, setComments] = useState({});
  const [statuses, setStatus] = useState({});
  const [showCommentField, setShowCommentField] = useState({});

  const handleAddComment = async (groupId, memberId, comment) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/groupParticipants/${memberId}/comments`, { comment });
      //await axios.put(`http://localhost:5000/backend/api/groupParticipants/${memberId}/comments`, { comment });
      // Assuming you're updating the comments locally upon successful API call
      setComments({ ...comments, [memberId]: comment });
      // Reset the showCommentField state for this member ID to hide the input field
      setShowCommentField({ ...showCommentField, [memberId]: false });
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handleApproval = async (groupId, status) => {
    try {
      let ChangedStatus = "";
      if (status == '' || status == null || status == 'Pending') {
        ChangedStatus = 'Approved';
      } else if (status == 'Approved') {
        ChangedStatus = 'Pending';
      }

      await axios.put(`${process.env.REACT_APP_API_URL}/api/groupApproval/${groupId}`, { status: ChangedStatus });
      setStatus({ ...statuses, [groupId]: ChangedStatus });
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const toggleCommentField = (memberId) => {
    setShowCommentField({ ...showCommentField, [memberId]: !showCommentField[memberId] });
  };

  useEffect(() => {
    if (authenticated) {
      // Fetch data from the API after authentication
      //axios.get('http://localhost:5000/backend/api/groupsWithParticipants')
      axios.get(`${process.env.REACT_APP_API_URL}/api/groupsWithParticipants`)
        .then(response => {
          setGroupsWithParticipants(response.data.groupsWithParticipants);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  }, [authenticated, comments, statuses]);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Check if the password is correct
    if (password === 'Admin12E!') {
      // Password is correct, set authenticated to true
      setAuthenticated(true);
    } else {
      // Password is incorrect, display an error message
      setError('Incorrect password. Please try again.');
    }
  };

  //const totalParticipants = groupsWithParticipants.reduce((total, group) => total + group.participants.length, 0);
  const totalParticipants = groupsWithParticipants.reduce((total, group) => {
    const activeParticipantsCount = group.participants.filter(participant => !participant.deleted).length;
    return total + activeParticipantsCount;
}, 0);

  return (
    <FormContainer component="main" maxWidth="xl">
      <StyledPaper elevation={2}>
        <h2>Report Page</h2>
        {!authenticated && (
          <form onSubmit={handleSubmit}>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <FormControl fullWidth >
                  <TextField
                    type="password"
                    label="Password"
                    fullWidth
                    value={password}
                    onChange={handlePasswordChange}
                    margin="dense"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button type="submit" variant="contained" color="primary">Submit</Button>
              </Grid>
            </Grid>

          </form>
        )}
        {authenticated && (
          <ReportMain>
            <h3>Authenticated! Here's the report:</h3>
            <p>Total Families: {groupsWithParticipants.length}</p>
            <p>Total Members: {totalParticipants}</p>
            <table className="styled-table">
              <thead>
                <tr>
                  {/*<th>Group ID</th>
                  <th>Member ID</th>
        */}
                  <th>Group Leader</th>
                  <th>Leader Email</th>
                  <th>Members</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Cell</th>
                  <th>Gender</th>
                  <th>Age</th>
                  <th>Relationship</th>
                  <th>Total Cost</th>
                  <th>Comments</th> {/* New column for Comments */}
                  <th>Action</th> {/* New column for Action */}
                  <th>Approval</th> {/* New column for Action */}
                </tr>
              </thead>
              <tbody>
                {groupsWithParticipants.map((group) =>
                  group.participants.map((member) => (
                    member.deleted ? null : 
                    <tr key={member._id} style={{ backgroundColor: group.status === 'Approved' ? '#9dd49d' : 'inherit' }}>
                      {/*<td data-label="Group ID">{group._id}</td>
                      <td data-label="Member ID">{member._id}</td>
                  */}
                      <td data-label="Group Leader">
                        {group.participants.filter((participant) => participant.relation === 'Myself').map((myself) => `${myself.firstName} ${myself.lastName}`)}
                      </td>
                      <td data-label="Leader Email">{group.email}</td>
                      <td data-label="Members">{group.participants.filter(participant => !participant.deleted).length}</td>
                      <td data-label="Name">{member.firstName} {member.lastName}</td>
                      <td data-label="Email">{member.email}</td>
                      <td data-label="Cell">{member.phone}</td>
                      <td data-label="Gender">{member.gender}</td>
                      <td data-label="Age">{member.age}</td>
                      <td data-label="Relationship">{member.relation}</td>
                      <td data-label="Total Cost">{group.amount}</td>
                      <td data-label="Comments">{member.comments || ''}</td> {/* Display member comments */}
                      <td data-label="Action">
                        {member.comments ? (
                          <Button type="button" variant="contained" color="primary" onClick={() => toggleCommentField(member._id)}>Edit</Button>
                        ) : (
                          <Button type="button" variant="contained" color="primary" onClick={() => toggleCommentField(member._id)}>Add Comment</Button>
                        )}
                        {showCommentField[member._id] && (
                          <div>
                            <input type="text" value={comments[member._id] || ''} onChange={(e) => setComments({ ...comments, [member._id]: e.target.value })} />
                            <Button type="button" variant="contained" color="primary" onClick={() => handleAddComment(group._id, member._id, comments[member._id])}>Save</Button>
                          </div>
                        )}
                      </td>
                      <td>
                        {member.relation === 'Myself' ? (
                          <FormControlLabel
                            control={<Switch checked={group.status === 'Approved'} onChange={() => handleApproval(group._id, group.status)} />}
                            label={group.status === 'Approved' ? 'Approved' : 'Pending'} // Change label based on group status
                          />
                        ) : group.status === 'Approved' ? 'Approved' : 'Pending'}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </ReportMain>
        )}
        {error && <div style={{ color: 'red' }}>{error}</div>}
      </StyledPaper>
    </FormContainer>
  );
};

// Styled components for enhanced design
const StyledPaper = styled(Paper)`
  padding: 50px;
  background-color: rgba(255,255,255,0.5) !important;
  position: relative;
`;

const FormContainer = styled(Container)`
  background-image: url(${backgroundImageURL});
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 50px;
`;

const ReportMain = styled.div`
.styled-table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }
  
  .styled-table thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
  }
  
  .styled-table th,
  .styled-table td {
    padding: 10px 5px;
  }
  
  .styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
  }
  
  .styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
  }
  
  .styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
  
  .styled-table tbody tr:nth-of-type(odd) {
    background-color: #ffffff;
  }
  
  .styled-table tbody tr:hover {
    background-color: #e0e0e0;
  }
  
  .styled-table tbody td {
    word-break: break-word;
  }

  .styled-table tbody td .MuiButton-root {
    font-size: 12px;
    padding: 5px;
  }
  /* Responsive CSS */
@media (max-width: 768px) {
  .styled-table thead {
    display: none;
  }
  
  .styled-table, .styled-table tbody, .styled-table tr, .styled-table td {
    display: block;
    /* width: 100%; */
  }

  .styled-table tr {
    margin-bottom: 15px;
  }

  .styled-table td {
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  .styled-table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: bold;
    text-align: left;
  }
}
`;
export default ReportPage;