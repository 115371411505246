import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import {
  Paper,
  Container,
  TextField,
  Button,
  Select,
  InputLabel,
  Grid,
  FormControl,
  FormControlLabel, Switch
} from '@mui/material';
const FormComponent = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [pinNumber, setPinNumber] = useState('');
    const [authenticated, setAuthenticated] = useState(false);
    const [groupsWithParticipants, setGroupsWithParticipants] = useState([]);

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.get(`${process.env.REACT_APP_API_URL}/api/groupsWithParticipantsByPhoneAndPin?phoneNumber=${phoneNumber}&pinNumber=${pinNumber}`)
        .then(response => {
            setAuthenticated(true);
            console.log(response.data.groupWithParticipants);
            setGroupsWithParticipants(response.data.groupWithParticipants);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    };
   

    return (
        <section className="form-sec">
            <div className='container-xl lg:container m-auto'>
                {authenticated ?
                <div className='bg-white p-5'>
                    <table className="styled-table">
                        <thead>
                            <tr>
                                {/*<th>Group ID</th>
                  <th>Member ID</th>
        */}
                                <th>Group Leader</th>
                                <th>Leader Email</th>
                                <th>Members</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Cell</th>
                                <th>Gender</th>
                                <th>Age</th>
                                <th>Relationship</th>
                                <th>Total Cost</th>
                                <th>Comments</th> {/* New column for Comments */}
                                <th>Approval</th> {/* New column for Action */}
                            </tr>
                        </thead>
                        <tbody>
                            {groupsWithParticipants.participants.map((member) => (
                                    //member?.deleted1 ? null :
                                        <tr key={member._id} style={{ backgroundColor: groupsWithParticipants.status === 'Approved' ? '#9dd49d' : 'inherit' }}>
                                            {/*<td data-label="Group ID">{group._id}</td>
                      <td data-label="Member ID">{member._id}</td>
                  */}
                                            <td data-label="Group Leader">
                                                {groupsWithParticipants.participants.filter((participant) => participant.relation === 'Myself').map((myself) => `${myself.firstName} ${myself.lastName}`)}
                                            </td>
                                            <td data-label="Leader Email">{groupsWithParticipants.email}</td>
                                            <td data-label="Members">{groupsWithParticipants.participants.filter(participant => !participant.deleted).length}</td>
                                            <td data-label="Name">{member.firstName} {member.lastName}</td>
                                            <td data-label="Email">{member.email}</td>
                                            <td data-label="Cell">{member.phone}</td>
                                            <td data-label="Gender">{member.gender}</td>
                                            <td data-label="Age">{member.age}</td>
                                            <td data-label="Relationship">{member.relation}</td>
                                            <td data-label="Total Cost">{groupsWithParticipants.amount}</td>
                                            <td data-label="Comments">{member.comments || ''}</td> {/* Display member comments */}
                                            <td>
                                                {groupsWithParticipants.status === 'Approved' ? 'Approved' : 'Pending'}
                                            </td>
                                        </tr>
                                ))}
                        </tbody>
                        </table>
                        </div>
                        : <div className="row">
                            <div className="col-lg-12">
                                <form onSubmit={handleSubmit} className='bg-white p-5'>
                                    <div className="mb-3">
                                        <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="phoneNumber"
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="pinNumber" className="form-label">PIN Number</label>
                                        <input
                                            type="password" // assuming PIN is sensitive information
                                            className="form-control"
                                            id="pinNumber"
                                            value={pinNumber}
                                            onChange={(e) => setPinNumber(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </form>
                            </div>
                        </div>
                    }
                </div>
         </section>
    );
};

export default FormComponent;