import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Paper, Container, Grid } from '@mui/material';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

const backgroundImageURL = '/divinemercyanakara.jpg';
const EditGroup = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const { id } = useParams(); // Assuming you're using React Router to extract the groupId from the URL
    const [groupWithParticipants, setGroupWithParticipants] = useState(null);

    const [totalCost, setTotalCost] = useState(0);
    const [finalCost, setFinalCost] = useState(0);
    const [RoomPrice, setRoomPrice] = useState(500);
    const [RoomCharges, setRoomCharges] = useState(0);
    const [rooms, setRooms] = useState(1);
    const [shareRoom, setRoomShare] = useState(1);
    const [numberOfMembers, setNumberOfMembers] = useState(1);
    const [numberOfAdults, setNumberOfAdults] = useState(0);
    const [numberOfTeens, setNumberOfTeens] = useState(0);
    const [numberOfChildren, setNumberOfChildren] = useState(0);
    const [numberOfToddlers, setNumberOfToddlers] = useState(0);
    const [numberOfInfants, setNumberOfInfants] = useState(0);

    useEffect(() => {
        if (id) {
            axios.get(`${process.env.REACT_APP_API_URL}/api/groupsWithParticipants/${id}`)
                .then(response => {
                    setGroupWithParticipants(response.data.groupWithParticipants);

                    // Extract values from response
                    const { participants, rooms, shareRoom, paymentMethod } = response.data.groupWithParticipants;

                    // Set default values if rooms or roomShare are empty
                    const parsedRooms = typeof rooms === 'undefined' ? 1 : parseInt(rooms);
                    const parsedRoomShare = typeof shareRoom === 'undefined' ? 1 : Number(shareRoom);
                    setRooms(parsedRooms);
                    setRoomShare(parsedRoomShare);
                    // Call UpdatePrice with default or parsed values
                    UpdatePrice(participants, parsedRooms, parsedRoomShare, paymentMethod);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [id]);
   
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        const parsedValue = name === "rooms" || name === "shareRoom" ? parseInt(value) : value; // Parse 'rooms' to an integer
        setGroupWithParticipants(prevState => ({
            ...prevState,
            [name]: parsedValue // Use parsedValue instead of value
        }));
        if (name === "rooms") {
            setRooms(parsedValue);
            const parsedRoomShare = Number(groupWithParticipants.shareRoom) || 1;
            UpdatePrice(groupWithParticipants.participants, parsedValue, parsedRoomShare, groupWithParticipants.paymentMethod); // Pass parsedValue instead of parseInt(groupWithParticipants.rooms)
        }
        if (name === "shareRoom") {
            setRoomShare(parsedValue);
            UpdatePrice(groupWithParticipants.participants, groupWithParticipants.rooms, parsedValue, groupWithParticipants.paymentMethod); // Pass parsedValue instead of parseInt(groupWithParticipants.rooms)
        }
    };
    const handleParticipantChange = (index, event) => {
        const { name, value } = event.target;
        const updatedParticipants = [...groupWithParticipants.participants];
        updatedParticipants[index] = {
            ...updatedParticipants[index],
            [name]: value
        };
        setGroupWithParticipants(prevState => ({
            ...prevState,
            participants: updatedParticipants
        }));

        UpdatePrice(updatedParticipants, parseInt(groupWithParticipants.rooms), Number(groupWithParticipants.shareRoom), groupWithParticipants.paymentMethod);

    };

    const addParticipant = () => {
        if (groupWithParticipants.participants.length < 8) {
            setGroupWithParticipants(prevState => ({
                ...prevState,
                participants: [...prevState.participants, {
                    firstName: '',
                    lastName: '',
                    email: '',
                    age: '',
                    comments: '',
                    gender: 'Female',
                    relation: 'Wife',
                    phone: ''
                }]
            }));
        } else {
            // Handle case where maximum number of participants is reached
            console.log("Maximum number of participants reached (8)");
        }
    };

    const deleteParticipant = (index) => {
        const updatedParticipants = [...groupWithParticipants.participants];
        const participantToUpdate = updatedParticipants[index];

        // Check if any data is present
        const dataPresent = Object.values(participantToUpdate).some(value => !!value);
        if (dataPresent) {
            // Update the 'deleted' column if data is present
            updatedParticipants[index] = {
                ...participantToUpdate,
                deleted: true
            };
        } else {
            // Delete participant if no data is present
            updatedParticipants.splice(index, 1);
        }

        setGroupWithParticipants(prevState => ({
            ...prevState,
            participants: updatedParticipants
        }));

        UpdatePrice(updatedParticipants, parseInt(groupWithParticipants.rooms), Number(shareRoom), groupWithParticipants.paymentMethod);
    };


    const UpdatePrice = (updatedFormData, rooms, shareRoom, paymentMethod) => {
        let totalCost = 0;

        // Filter out deleted participants
        const filteredFormData = updatedFormData.filter(person => !person.deleted);

        const nOfMembers = filteredFormData.filter(person => person.age >= 0).length;
        const nOfAdults = filteredFormData.filter(person => person.age >= 18).length;
        const nOfTeens = filteredFormData.filter(person => person.age < 18 && person.age >= 13).length;
        const nOfChildren = filteredFormData.filter(person => person.age < 13 && person.age >= 5).length;
        const nOfToddlers = filteredFormData.filter(person => person.age < 5 && person.age >= 3).length;
        const nOfInfants = filteredFormData.filter(person => person.age < 3).length;

        setNumberOfMembers(nOfMembers);
        setNumberOfAdults(nOfAdults);
        setNumberOfTeens(nOfTeens);
        setNumberOfChildren(nOfChildren);
        setNumberOfToddlers(nOfToddlers);
        setNumberOfInfants(nOfInfants);

        if (!rooms) {
            rooms = 1;
        }
        let roomPrice = RoomPrice * rooms;
        if (shareRoom === 1 && nOfMembers === 1 && rooms === 1) {
            roomPrice = RoomPrice / 2;
        } else {
            //setRoomShare(0);
        }
        setRoomCharges(roomPrice);

        if (nOfMembers === 1) {
            totalCost = roomPrice + nOfAdults * 200;
        } else {
            totalCost = roomPrice + nOfAdults * 200 + nOfTeens * 150 + nOfChildren * 75 + nOfToddlers * 50;
        }

        // Adjusted totalCost calculation

        setTotalCost(totalCost);

        if (paymentMethod === 'online') {
            totalCost = totalCost + (totalCost * 3 / 100);
        }
        setFinalCost(totalCost);
    };


    const submitForm = async () => {
        setLoading(true);
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/groupsWithParticipants/${id}`, {
                groupData: {
                    email: groupWithParticipants.email,
                    phone: groupWithParticipants.phone,
                    numberOfParticipants: groupWithParticipants.participants.length,
                    amount: finalCost,
                    roomNumber: groupWithParticipants.roomNumber,
                    rooms: groupWithParticipants.rooms,
                    shareRoom: Number(groupWithParticipants.shareRoom)
                    // Include other group data here if needed
                },
                participants: groupWithParticipants.participants
            });

            setLoading(false);

            // If the request was successful, navigate to 'group-report' page
            window.history.replaceState({}, ''); // Remove query params from URL
            navigate('/group-report', { state: { password: 'Admin12E!' } });

            // Handle successful response
            // You can further handle the response data or perform other actions as needed
        } catch (error) {
            setLoading(false);
            // Handle error
            console.error('Error creating group:', error);
            // You can display an error message to the user or handle it appropriately
        }
    };
    return (
        <FormContainer component="main" maxWidth="xl">
            <StyledPaper elevation={2}>
                {groupWithParticipants && (
                    <div>
                        <h2>Edit Group</h2>
                        {/* Render other editable fields similarly */}

                        <div className='fields'>
                            <label> Room #</label>
                            <input
                                type="text"
                                name="roomNumber"
                                value={groupWithParticipants?.roomNumber}
                                onChange={(event) => handleInputChange(event)}
                            />
                            <select name="rooms" onChange={handleInputChange}>
                                {[...Array(100).keys()].map(room => (
                                    <option key={room + 1} value={room + 1} selected={parseInt(groupWithParticipants.rooms) === room + 1}>
                                        {room + 1}
                                    </option>
                                ))}
                            </select>
                            <select name="shareRoom" onChange={handleInputChange}>
                                <option value={1} selected={Number(groupWithParticipants.shareRoom) === 1}>
                                    Yes
                                </option>
                                <option value={0} selected={Number(groupWithParticipants.shareRoom) === 0}>
                                    No
                                </option>
                            </select>

                        </div>
                        <h3>Participants:</h3>
                        {groupWithParticipants.participants.map((participant, index) => (
                            <div className={participant.deleted ? 'deleted fields' : 'fields'} key={index}>
                                <label>Participant {index + 1}:</label>
                                <input
                                    type="text"
                                    name="firstName"
                                    value={participant.firstName}
                                    disabled={participant.deleted}
                                    onChange={(event) => handleParticipantChange(index, event)}
                                />
                                <input
                                    type="text"
                                    name="lastName"
                                    value={participant.lastName}
                                    disabled={participant.deleted}
                                    onChange={(event) => handleParticipantChange(index, event)}
                                />
                                <input
                                    type="email"
                                    name="email"
                                    value={participant.email}
                                    disabled={participant.relation === 'Myself' || participant.deleted}
                                    onChange={(event) => handleParticipantChange(index, event)}
                                />
                                <input
                                    type="tel"
                                    name="phone"
                                    value={participant.phone}
                                    disabled={participant.relation === 'Myself' || participant.deleted}
                                    onChange={(event) => handleParticipantChange(index, event)}
                                />
                                <select name="gender" disabled={participant.deleted} onChange={(event) => handleParticipantChange(index, event)}>
                                    <option value="Female" selected={participant.gender === 'Female'}>Female</option>
                                    <option value="Male" selected={participant.gender === 'Male'}>Male</option>
                                </select>
                                <select name="relation" disabled={participant.relation === 'Myself' || participant.deleted} onChange={(event) => handleParticipantChange(index, event)}>
                                    {participant.relation ? <option value="Myself" selected={participant.relation === 'Myself'}>Myself</option> : null}
                                    <option value="Wife" selected={participant.relation === 'Wife'}>Wife</option>
                                    <option value="Husband" selected={participant.relation === 'Husband'}>Husband</option>
                                    <option value="Mother" selected={participant.relation === 'Mother'}>Mother</option>
                                    <option value="Father" selected={participant.relation === 'Father'}>Father</option>
                                    <option value="Son" selected={participant.relation === 'Son'}>Son</option>
                                    <option value="Daughter" selected={participant.relation === 'Daughter'}>Daughter</option>
                                    <option value="Brother" selected={participant.relation === 'Brother'}>Brother</option>
                                    <option value="Sister" selected={participant.relation === 'Sister'}>Sister</option>
                                    <option value="Grand Father" selected={participant.relation === 'Grand Father'}>Grand Father</option>
                                    <option value="Grand Mother" selected={participant.relation === 'Grand Mother'}>Grand Mother</option>
                                    <option value="Grand Son" selected={participant.relation === 'Grand Son'}>Grand Son</option>
                                    <option value="Grand Daughter" selected={participant.relation === 'Grand Daughter'}>Grand Daughter</option>
                                </select>
                                <select name="age" onChange={(event) => handleParticipantChange(index, event)} disabled={participant.deleted}>
                                    {[...Array(100).keys()].map((age) => (
                                        <option key={age} value={age} selected={participant.age === age}>
                                            {age}
                                        </option>
                                    ))}
                                </select>
                                <textarea
                                    name="comments"
                                    value={participant.comments}
                                    disabled={participant.deleted}
                                    onChange={(event) => handleParticipantChange(index, event)}
                                />
                                {/*participant.relation === 'Myself' || participant.deleted == true ? null : <button type="button" onClick={() => deleteParticipant(index)}>Delete</button>*/}
                                <button type="button" onClick={() => deleteParticipant(index)}>Delete</button>
                            </div>
                        ))}
                        <button class="add" type="button" onClick={addParticipant}>Add Participant</button>
                        <button class="add" type="button" disabled={loading} onClick={submitForm}>{loading ? 'Loading...' : 'Submit'}</button>

                        <Grid item xs={6}>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Charge Details</th>
                                        <th>Charges</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td data-label="Charge Details">Rooms ({rooms})</td>
                                        <td data-label="Charges">{RoomCharges.toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td data-label="Charge Details">Adult ({numberOfAdults})</td>
                                        <td data-label="Charges">{(numberOfAdults * 200).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td data-label="Charge Details">Teens ({numberOfTeens})</td>
                                        <td data-label="Charges">{(numberOfTeens * 150).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td data-label="Charge Details">Children ({numberOfChildren})</td>
                                        <td data-label="Charges">{(numberOfChildren * 75).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td data-label="Charge Details">Toddlers ({numberOfToddlers})</td>
                                        <td data-label="Charges">{(numberOfToddlers * 50).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td data-label="Charge Details">Infants ({numberOfInfants})</td>
                                        <td data-label="Charges">{(numberOfInfants * 0).toFixed(2)}</td>
                                    </tr>
                                    {groupWithParticipants.paymentMethod === 'online' && (
                                        <tr>
                                            <td data-label="Charge Details" style={{ color: 'red' }}>Credit Card Fee</td>
                                            <td data-label="Charges" style={{ color: 'red' }}>{(totalCost * 0.03).toFixed(2)}</td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td data-label="Charge Details"><strong>Total Charges</strong></td>
                                        <td data-label="Charges"><strong>{finalCost.toFixed(2)}</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                        </Grid>

                    </div>
                )}
            </StyledPaper>
        </FormContainer>
    );
};

// Styled components for enhanced design
const StyledPaper = styled(Paper)`
  padding: 50px;
  background-color: rgba(255,255,255,0.5) !important;
  position: relative;
`;

const FormContainer = styled(Container)`
  background-image: url(${backgroundImageURL});
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 50px;
  .fields {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 15px;
    &.deleted {
        background: #ff000075;
    }
    label{
        width: 100%;
    }
    input, select, textarea {
        background: transparent;
        border: solid 1px;
        padding: 10px 15px;
        margin: 5px;
        border-color: rgb(143 143 143);
        width: 10%;
        border-radius: 6px;
    }
    textarea{
        height: 46px;
    }
    button {
        padding: 6px 16px;
        border-radius: 4px;
        background: #f70000;
        color: #fff;
        border: none;
    }
  }
  .add {
    padding: 6px 16px;
    background: #000;
    color: #fff;
    border: none;
    margin: 30px 8px 15px;
    border-radius: 4px;
  } 
`;

export default EditGroup;