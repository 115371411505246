import { useState } from 'react';
import Hero from './components/Hero';
import HomeCards from './components/HomeCards';
import Navbar from './components/Navbar';
import styled from 'styled-components';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import FormComponent from './components/FormComponent';
const backgroundImageURL = '/divinemercyanakara.jpg';

const Wrapper = styled.div`
  background-image: url(${backgroundImageURL});
  background-size: cover;
  background-position: center;
  position: relative;
`;


const HomePage = () => {

    const [playingVideo, setPlayingVideo] = useState(null);

    const playVideo = (videoId) => {
        setPlayingVideo(videoId);
    };
    
    const videos = ['xX4M9o9qTHc', '2lM-2VIFvj4', 'EP3i3bgzamI', '64xCE51AukU', 'KYVfmFBYD8Q', 'QVlTyogAlsg', 'kxDOwOTpq1c', '0hlt8fBnbMs', ''];

    return (
        <Wrapper>
            <Navbar />
            <Hero />
            <HomeCards />
            <FormComponent />
            <section className='py-4'>
                <div className='container-xl lg:container m-auto'>
                <OwlCarousel
                        items={3}
                        autoplay={true}
                        autoplayHoverPause={true}
                        loop={true}
                        autoplayTimeout={5000}
                        margin={20}
                        responsive={{
                            0: {
                                items: 1,
                            },
                            600: {
                                items: 2,
                            },
                            992: {
                                items: 3,
                            },
                            1200: {
                                items: 3,
                            },
                        }}
                    >
                        {videos.map((videoId, index) => (
                            <div key={index} className="video-container">
                                {playingVideo === videoId ? (
                                    <iframe
                                        width="100%"
                                        height="315"
                                        src={`https://www.youtube.com/embed/${videoId}`}
                                        title={`YouTube Video ${index + 1}`}
                                        frameBorder="0"
                                        allowFullScreen
                                    ></iframe>
                                ) : (
                                    <img
                                        src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
                                        alt={`YouTube Video Thumbnail ${index + 1}`}
                                        onClick={() => playVideo(videoId)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                )}
                            </div>
                        ))}
                    </OwlCarousel>
            </div>
            </section>
        </Wrapper>
    );
};
export default HomePage;