import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import {
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    Paper,
    Typography,
    Box,
    Container
} from '@mui/material';
import styled from 'styled-components';

const backgroundImageURL = '/divinemercyanakara.jpg';

// Styled components for enhanced design
const StyledPaper = styled(Paper)`
  padding: 50px;
  background-color: rgba(255,255,255,0.5) !important;
  position: relative;
`;

const FormContainer = styled(Container)`
  background-image: url(${backgroundImageURL});
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 50px;
`;


const Success = () => {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [email, setEmail] = useState(null); // Initialize as null

    const location = useLocation();

    useEffect(() => {
        const fetchData = async () => {
            // Retrieve templateParams from localStorage
            const templateParam = JSON.parse(localStorage.getItem('templateParams'));
            
            localStorage.removeItem('templateParams');
            
            // Parse URL parameters to get payment intent
            const urlParams = new URLSearchParams(location.search);
            const paymentIntent = urlParams.get('payment_intent');
            const email = urlParams.get('email');
            setEmail(email);
            if (paymentIntent && templateParam && !formSubmitted) { // Check if form is not already submitted
                // Show success alert
                //alert('Payment successful!');

                try {
                    // Send email using emailjs
                    const emailResponse = await emailjs.send('service_v82azqs', 'template_oaep9dk', templateParam, {
                        publicKey: 'hW_p-4bygZnZcDdbb',
                    });
                    setFormSubmitted(true);
                    console.log('Email sent successfully:', emailResponse);
                } catch (error) {
                    console.error('Error sending email:', error);
                }
            }
        };

        fetchData();
    }, []); // Removed formSubmitted from dependency array

    return (
        <div>
            {formSubmitted &&
                <FormContainer component="main" maxWidth="xl">
                    <StyledPaper elevation={2}>
                        <Grid container>
                            <Grid item xs={12}>

                                <Typography variant="h4" align="center" gutterBottom>
                                Exodus 15:26: I am the Lord who heals you!
                                    <br />Prayers & Blessings from Grace Anointing Ministries, USA!!
                                </Typography>


                                <Typography variant="h4" align="center" gutterBottom>
                                Thank you! Your registration for the Chicago Residential Retreat has been received. Use this link to join the WhatsApp group: https://chat.whatsapp.com/HjrW9zbQ8uUDpI1V4UqJ5k 
                                    {/*<br />A confirmation email has been sent to {email}.*/}
                            
                            Registration will be confirmed upon payment verification, and you will receive a confirmation email at {{email}}.
                                </Typography>
                                <Typography variant="h4" align="center" gutterBottom>

                                </Typography>
                            </Grid>
                        </Grid>

                    </StyledPaper>
                </FormContainer>
            }
            {/* You can add more UI elements here */}
        </div>
    );
};

export default Success;