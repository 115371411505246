import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button as Button1 } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import 'primeicons/primeicons.css';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import {
  Paper,
  Container,
  TextField,
  Button,
  Select,
  InputLabel,
  Grid,
  FormControl,
  FormControlLabel, Switch
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
const backgroundImageURL = '/divinemercyanakara.jpg';


const GroupReportPage = () => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const [groupsWithParticipants, setGroupsWithParticipants] = useState([]);
  const [comments, setComments] = useState({});
  const [globalFilter, setGlobalFilter] = useState(null);
  const [statuses, setStatus] = useState({});
  const [expandedData, setexpandedData] = useState([]);
  const [showCommentField, setShowCommentField] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  // Function to handle edit action
  const handleEdit = (rowId) => {
    navigate(`/edit-group/${rowId}`);
  };

  const dt = useRef(null);
  useEffect(() => {
    const statePass = location.state ? location.state.password : null;

    if (statePass === 'Admin12E!') {
      // Password is correct, set authenticated to true
      setAuthenticated(true);
    }
  }, [location.state]);
  const handleAddComment = async (groupId, memberId, comment) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/groupParticipants/${memberId}/comments`, { comment });
      //await axios.put(`http://localhost:5000/backend/api/groupParticipants/${memberId}/comments`, { comment });
      // Assuming you're updating the comments locally upon successful API call
      setComments({ ...comments, [memberId]: comment });
      // Reset the showCommentField state for this member ID to hide the input field
      setShowCommentField({ ...showCommentField, [memberId]: false });
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handleApproval = async (groupId, status) => {
    try {
      let ChangedStatus = "Pending";
      if (status == '' || status == null) {
        ChangedStatus = 'Approved';
      } else if (status == 'Approved') {
        ChangedStatus = 'Pending';
      }

      await axios.put(`${process.env.REACT_APP_API_URL}/api/groupApproval/${groupId}`, { status: ChangedStatus });
      setStatus({ ...statuses, [groupId]: ChangedStatus });
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const toggleCommentField = (memberId) => {
    setShowCommentField({ ...showCommentField, [memberId]: !showCommentField[memberId] });
  };

  useEffect(() => {
    if (authenticated) {
      // Fetch data from the API after authentication
      //axios.get('http://localhost:5000/backend/api/groupsWithParticipants')
      axios.get(`${process.env.REACT_APP_API_URL}/api/groupsWithParticipants`)
        .then(response => {
          //setGroupsWithParticipants(response.data.groupsWithParticipants);
          // Assuming response.data.groupsWithParticipants is an array of group objects
          const updatedGroupsWithParticipants = response.data.groupsWithParticipants.map(group => {
            // Find the group leader among participants
            const groupLeader = group.participants.find(participant => participant.relation === 'Myself');

            // Calculate age if birthdate is available
            const age = groupLeader ? groupLeader.age : null;

            // Return updated group object with groupLeader, age, and gender
            return {
              ...group,
              groupLeader: groupLeader ? `${groupLeader.firstName} ${groupLeader.lastName}` : '',
              age: age,
              gender: groupLeader ? groupLeader.gender : '',
              EmergencyContactDetails: group ? `${group.emergencyContactPersonName} - ${group.emergencyContactNumber}` : ''
            };
          });
          // Set the updated data structure
          setGroupsWithParticipants(updatedGroupsWithParticipants);
          const expandedData1 = [];
          updatedGroupsWithParticipants.forEach(group => {
            group.participants.forEach(participant => {
                if (!participant.deleted) { // Check if participant is not deleted
                    expandedData1.push({
                        groupLeader: participant.relation === 'Myself' ? true : false,
                        deleted: participant.deleted ? true : false,
                        participantName: `${participant.firstName} ${participant.lastName}`,
                        phone: participant.relation === 'Myself' ? group.phone : participant.phone,
                        email: participant.relation === 'Myself' ? group.email : participant.email,
                        roomNumber: participant.relation === 'Myself' ? group.roomNumber : null,
                        gender: participant.gender,
                        relation: participant.relation,
                        comments: participant.comments,
                        age: participant.age,
                        rooms: group.rooms,
                        amount: group.amount,
                        status: group.status === 'Approved' ? 'Approved' : 'Pending',
                        memberCount: participant.relation === 'Myself' ? group.participants.filter(participant => !participant.deleted).length : null,
                        address: group.address,
                        paymentMethod: participant.relation === 'Myself' ? group.paymentMethod : null,
                        emergencyContactDetails: participant.relation === 'Myself' ? `${group.emergencyContactPersonName}, ${group.emergencyContactNumber}` : null,
                        specialRequest: participant.relation === 'Myself' ? group.specialRequest : null,
                        _id: group._id
                    });
                }
            });
        });
          setexpandedData(expandedData1);
          console.log(expandedData1);

        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  }, [authenticated, comments, statuses]);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Check if the password is correct
    if (password === 'Admin12E!') {
      // Password is correct, set authenticated to true
      setAuthenticated(true);
    } else {
      // Password is incorrect, display an error message
      setError('Incorrect password. Please try again.');
    }
  };
  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };
  //const totalParticipants = groupsWithParticipants.reduce((total, group) => total + group.participants.length, 0);

  const totalParticipants = groupsWithParticipants.reduce((total, group) => {
    const activeParticipantsCount = group.participants.filter(participant => !participant.deleted).length;
    return total + activeParticipantsCount;
}, 0);


  const header = (
    <div className="flex align-items-center justify-content-end gap-2 mb-2">
      <input type="text" className="p-inputtext" placeholder="Search..." onChange={(e) => setGlobalFilter(e.target.value)} />
      <Button1 type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="Export CSV" label="Export CSV" className='p-button p-component p-button-rounded' />
    </div>
  );

  return (
    <FormContainer component="main" maxWidth="xl">
      <StyledPaper elevation={2}>
        <h2>Report Page</h2>
        {!authenticated && (
          <form onSubmit={handleSubmit}>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <FormControl fullWidth >
                  <TextField
                    type="password"
                    label="Password"
                    fullWidth
                    value={password}
                    onChange={handlePasswordChange}
                    margin="dense"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button type="submit" variant="contained" color="primary">Submit</Button>
              </Grid>
            </Grid>

          </form>
        )}
        {authenticated && (
          <ReportMain>
            <h3>Authenticated! Here's the report:</h3>
            <p>Total Families: {groupsWithParticipants.length}</p>
            <p>Total Members: {totalParticipants}</p>
            {/*<table className="styled-table">
              <thead>
                <tr>
                  {/*<th>Group ID</th>
                  <th>Member ID</th>
        */}
            {/* <th>Group Leader</th>
                  <th>Leader Email</th>
                  <th>Members</th>
                  <th>Address</th>
                  <th>Payment Type</th>
                  <th>Emergency Contact Details</th>
                  <th>Special Instruction</th>
                </tr>
              </thead>
              <tbody>
                {groupsWithParticipants.map((group) => (
                  <tr key={group._id} style={{ backgroundColor: group.status === 'Approved' ? '#9dd49d' : 'inherit' }}>
                    <td data-label="Group Leader">
                      {group.participants.filter((participant) => participant.relation === 'Myself').map((myself) => `${myself.firstName} ${myself.lastName}`)}
                    </td>
                    <td data-label="Leader Email">{group.email}</td>
                    <td data-label="Members">{group.participants.length}</td>
                    <td data-label="Address">{group.address}</td>
                    <td data-label="Payment Type">{group.paymentMethod}</td>
                    <td data-label="Emergency Contact Details"><p>{group.emergencyContactPersonName}</p><strong>{group.emergencyContactNumber}</strong></td>
                    <td data-label="Special Instruction">{group.specialRequest}</td>
                  </tr>
                ))}
              </tbody>
              </table>*/}

            <Tooltip target="button" position="bottom" />
            <DataTable
              ref={dt}
              value={expandedData}
              showGridlines
              header={header}
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 20]}
              tableStyle={{ backgroundColor: "transparent" }}
              globalFilter={globalFilter} // Add globalFilter property
              globalFilterFields={['participantName', 'email', 'phone', 'memberCount', 'address', 'paymentMethod']}
              emptyMessage="No records found" // Optional: message to display when no records match the filter
              filterMode="lenient" // Set filterMode to lenient for searching across all fields
            >
              <Column field="participantName" header="Participant Name" />
              <Column field="phone" header="Phone" />
              <Column field="email" header="Email" />
              <Column field="gender" header="Gender" />
              <Column field="age" header="Age" />
              <Column field="memberCount" header="Members" />
              <Column field="address" header="Address" />
              <Column field="paymentMethod" header="Payment Type" />
              <Column field="emergencyContactDetails" header="Emergency Contact Details" />
              <Column field="specialRequest" header="Special Instruction" />
              <Column field="comments" header="Comments" />
              <Column field="rooms" header="Rooms" />
              <Column field="roomNumber" header="Room Number" />
              <Column field="status" header="Status" />
              <Column field="amount" header="Amount" />
              <Column header="Actions" body={rowData => (
                rowData.groupLeader ? <Button label="Edit" onClick={() => handleEdit(rowData._id)}>Edit</Button> : null 
              )} />
            </DataTable>
          </ReportMain>
        )}
        {error && <div style={{ color: 'red' }}>{error}</div>}
      </StyledPaper>
    </FormContainer>
  );
};

// Styled components for enhanced design
const StyledPaper = styled(Paper)`
  padding: 50px;
  background-color: rgba(255,255,255,0.5) !important;
  position: relative;
`;

const FormContainer = styled(Container)`
  background-image: url(${backgroundImageURL});
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 50px;
`;

const ReportMain = styled.div`
.styled-table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }
  
  .styled-table thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
  }
  
  .styled-table th,
  .styled-table td {
    padding: 10px;
  }
  
  .styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
  }
  
  .styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
  }
  
  .styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
  
  .styled-table tbody tr:nth-of-type(odd) {
    background-color: #ffffff;
  }
  
  .styled-table tbody tr:hover {
    background-color: #e0e0e0;
  }
  
  .styled-table tbody td {
    word-break: break-word;
    min-width: 150px; 
  }

  .styled-table tbody td .MuiButton-root {
    font-size: 12px;
    padding: 5px;
  }
  /* Responsive CSS */
@media (max-width: 768px) {
  .styled-table thead {
    display: none;
  }
  
  .styled-table, .styled-table tbody, .styled-table tr, .styled-table td {
    display: block;
    /* width: 100%; */
  }

  .styled-table tr {
    margin-bottom: 15px;
  }

  .styled-table td {
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  .styled-table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: bold;
    text-align: left;
  }
}
`;
export default GroupReportPage;