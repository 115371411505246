import banner1 from "../banner1.jpeg";
import banner2 from "../banner2.jpeg";
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
const Hero = () => {
    const images = [
        banner1,
        banner2
        // Add more image URLs here as needed
      ];
    return (
        <Carousel className="custom-carousel">
            {images.map((image, index) => (
            <Carousel.Item key={index}>
                <img
                className="d-block w-100"
                src={image}
                alt={`Slide ${index}`}
                />
            </Carousel.Item>
            ))}
        </Carousel>
    );
  };
  export default Hero;