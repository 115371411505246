import React, { useState, useEffect } from 'react';
import Card from './Card'; // Import your Card component
import jsonData from './data.json'; // Import your JSON data file
import { Link } from 'react-router-dom';
const HomeCards = () => {
  const [cardsData, setCardsData] = useState([]);

  useEffect(() => {
    // Simulate fetching data from JSON file (replace with actual fetch logic if needed)
    // Here, jsonData is the imported JSON data
    setCardsData(jsonData);
  }, []);

  return (
    <section className='py-4'>
      <div className='container-xl lg:container m-auto'>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 p-4 rounded-lg p-4'>
          {cardsData.map((card, index) => (
            <Card key={index} bg={card.bg}>
              <h2 className='text-2xl font-bold'>{card.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: card.description }} />
              {card.buttonText && card.link ? <Link
            to={card.link}
            className={`inline-block bg-${card.buttonColor} text-white rounded-lg px-4 py-2 hover:bg-${card.hoverColor} ${card.IsActive === 'no' ? 'opacity-50 cursor-not-allowed' : ''}`}
            style={{ pointerEvents: card.IsActive === 'no' ? 'none' : 'auto' }}
            disabled={card.IsActive === 'no'}
              >
                {card.buttonText}
              </Link> : null}
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HomeCards;