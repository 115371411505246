import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  Paper,
  Container,
  Grid,
  FormControl
} from '@mui/material';
const backgroundImageURL = '/divinemercyanakara.jpg';


// Styled components for enhanced design
const StyledPaper = styled(Paper)`
  padding: 50px;
  background-color: rgba(255,255,255,0.5) !important;
  position: relative;
`;

const FormContainer = styled(Container)`
  background-image: url(${backgroundImageURL});
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 50px;
  min-height: 100vh;
  .PhoneInputInput{
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
  }

  .price-notes {
    h2 {
      font-size: 24px;
      margin-bottom: 15px;
    }
    .section {
      h3 {
        font-size: 20px;
        margin-top: 20px;
      }
  
      ul {
        list-style-type: none;
        padding-left: 0;
  
        li {
          margin-bottom: 10px;
  
          &.sublist {
            margin-left: 20px;
          }
        }
      }
    }
  }

`;

// Form header
const FormHeader = styled.div`
    text-align: center;
    margin-bottom: 20px;
`;

// Form title
const FormTitle = styled.h2`
    font-size: 24px;
    font-weight: bold;
    color: #333;
`;

// Form input fields
const FormInput = styled.input`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
`;

// Submit button
const SubmitButton = styled.button`
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: auto;
    &:hover {
        background-color: #0056b3;
    }
`;

const SendVerification = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState('');
  const [message, setMessage] = useState('');
  const [verificationSent, setVerificationSent] = useState(false);
  const [timer, setTimer] = useState(300); // Timer for OTP
  const [codeExpired, setCodeExpired] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  useEffect(() => {
    let interval;
    if (verificationSent) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(interval);
            setCodeExpired(true);
            setVerificationSent(false);
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [verificationSent]);

  const handleSendVerification = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/send-verification`, { number: phone, email: email });
      setMessage(response.data.status);
      setVerificationSent(true);
      setCodeExpired(false);
      setTimer(300); // Reset timer
    } catch (error) {
      setMessage('Error sending verification: ' + error.response?.data?.error || 'An error occurred.');
      alert('Error sending verification: ' + error.response?.data?.error || 'An error occurred.');
    }
    setLoading(false); // Stop loading
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/check-verification`, { number: phone, code: otp });
      if (response.data.status === 'approved') {
        navigate('/retreat-registration', { state: { firstName, lastName, email, phone } });
      } else {
        setMessage('Invalid verification code.');
      }
    } catch (error) {
      setMessage('Error verifying code: ' + error.response?.data?.error || 'An error occurred.');
    }
    setLoading(false); // Stop loading
  };

  const handleResendCode = () => {
    handleSendVerification();
  };

  return (
    <FormContainer component="main" maxWidth="xl">
      <StyledPaper elevation={2}>
        <Container>
          <FormHeader>
          <FormTitle>Register for Chicago Krupanubhavam Residential retreat</FormTitle>
          <FormTitle>Led by Fr. Dominic Valanmanal</FormTitle>
			<p class='mt-2 mb-1' style={{color: 'red', fontSize: '20px'}}>(Please email info@graceanointing.org or call anyone below if there are any questions.)</p>
            {/*
            <FormTitle>Register for Chicago Residential Retreat</FormTitle>
			<p class='mt-2 mb-1' style={{color: 'red', fontSize: '20px'}}>(Registration will be closed on June 15.)</p>
  */}
          </FormHeader>
          {!verificationSent || codeExpired ? (
            <form onSubmit={handleSendVerification}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth ><FormInput type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="First Name" /></FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth ><FormInput type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Last Name" /></FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth ><FormInput type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" /></FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth ><PhoneInput
                    international
                    defaultCountry="US"
                    value={phone}
                    onChange={setPhone}
                    placeholder="Enter phone number"
                    style={{
                      width: '98%', // Adjust the width as needed
                      height: '40px', // Adjust the height as needed
                      fontSize: '16px', // Adjust the font size as needed
                      paddingLeft: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      backgroundColor: '#fff',
                      // Add any other styles you want to apply to the input
                    }}
                  /></FormControl>
                </Grid>
                <Grid item xs={12}>
                  <SubmitButton type="submit" disabled={loading}>{loading ? 'Loading...' : 'Send Verification'}</SubmitButton>
                </Grid>
              </Grid>
            </form>
          ) : (
            <form onSubmit={handleVerify}>
              <FormInput type="text" value={otp} onChange={(e) => setOtp(e.target.value)} placeholder="Enter OTP" />
              <p>Time remaining: {timer} seconds</p>
              <SubmitButton type="submit" disabled={loading}>{loading ? 'Loading...' : 'Verify'}</SubmitButton>
            </form>
          )}
          {codeExpired && (
            <SubmitButton onClick={handleResendCode} disabled={loading}>{loading ? 'Loading...' : 'Resend Code'}</SubmitButton>
          )}
          {message && <p style={{color:"red"}}>{message}</p>}

          <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <p>Please call any of the numbers below if you have any questions during the registration:</p>
                  <p>Msgr. Thomas Mulavanal</p>
                  <p>Stephen Chollampel  (847) 772-4292</p>                  
                  <p>Lizy Alex Mullapally (847) 730-9103</p>
                  <p>Annamma Thekkeparambil (847) 606-8520</p>
                  <p>George Pullorkunnel (630) 776-6001</p>
                  <p>Sheeba Sabu Mutholath (708) 307-1793</p>
                  <p>Suraj Koladyil  (630) 880-6573</p>
                  <p>Thomaskutty Thekkanatt (773) 799 -4796</p>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className="price-notes">
                  <h2>Chicago Stayin Retreat Fee Details</h2>
                  <div className="section">

                  <ul>
                      <li>Single Person  with no sharing: 385+200=$585</li>
                      <li className="sublist">
                        <strong>Shared Accommodation:</strong>
                        <ul>
                        <li>First 2 participants (Adult/Kid) $385 each</li>
                          <li>Additional Adults $230 each</li>
                          <li>Additional Kids (5-18yrs) $210 each</li>
                          <li>Kids under 5 yrs free</li>
                        </ul>
                      </li>
                    </ul>
                


{/* Miami Registration fee begin */}
                    {/*
                    <h3>Accommodation:</h3>
                    <ul>
                      <li>Room w/ 2 double beds (4 days & 3 nights): $500</li>
                      <li className="sublist">
                        <strong>Shared Accommodation:</strong>
                        <ul>
                          <li>A family of 4-5 in one room: $500</li>
                          <li>Two singles sharing a room ($250 per head)</li>
                        </ul>
                      </li>
                      <li>Exclusive Room (for one person): $500</li>
                    </ul>
                  </div>
                  <div className="section">
                    <h3>Additional Fees:</h3>
                    <ul>
                      <li>Adult (18+): $200</li>
                      <li>Teens (13-17): $150</li>
                      <li>Children (5-12): $75</li>
                      <li>Kids (3-4): $50</li>
                      <li>Below 3: Free</li>
                    </ul>
                    */}
                  </div>
        
                </div>
                </Grid>
          </Grid>

        </Container>
      </StyledPaper>
    </FormContainer>
  );
};

export default SendVerification;